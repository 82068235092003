import {
    CARRIER_REQUEST_RESPONSE,
    CREATE_QUOTE_REQUEST,
    DOWNLOAD_CAMPAIGN_FILE,
    DOWNLOAD_PROCESSED_CAMPAIGN_FILE,
    GET_ALL_APPOINTMENTS_BY_DFS,
    GET_ALL_VENDOR_CONFIGS,
    GET_APPLIED_CARRIERS,
    GET_CAMPAIGN_DETAILS,
    GET_CAMPAIGN_FILE_DETAILS, GET_CAMPAIGN_SMS_STATUS,
    GET_LAST_RAN_TIME_LSA_BATCH,
    GET_QUOTE_ELIGIBILITY,
    GET_QUOTE_REQUEST,
    GET_QUOTE_RESPONSE,
    GET_QUOTE_SEARCH_RESULTS,
    GET_RATING_PAYLOAD,
    GET_USER_ROLE,
    OVERRIDE_ELIGIBILITY,
    TOP_FIVE_COUNTRIES,
    TOP_FIVE_PRODUCERS,
    TRIGGER_LSA_BATCH_PROCESS,
    UPDATE_APPOINTMENTS_BY_DFS
} from "../util/constants";

export const AppService = () => {
    const getTopFiveCounties = async (execute) => {
        return execute(
            TOP_FIVE_COUNTRIES.HTTP_METHOD,
            `${TOP_FIVE_COUNTRIES.URL}${getTodaysDate()}`
        );
    };

    const getTopFiveProducers = async (execute) => {
        return execute(
            TOP_FIVE_PRODUCERS.HTTP_METHOD,
            `${TOP_FIVE_PRODUCERS.URL}${getTodaysDate()}`
        )
    };

    const getQuoteRequest = async (execute, body) => {
        return execute(
            GET_QUOTE_REQUEST.HTTP_METHOD,
            GET_QUOTE_REQUEST.URL,
            body
        )
    };

    const getQuoteResponse = async (execute, body) => {
        return execute(
            GET_QUOTE_RESPONSE.HTTP_METHOD,
            GET_QUOTE_RESPONSE.URL,
            body
        )
    };

    const getRatingPayload = async (execute, body) => {
        return execute(
            GET_RATING_PAYLOAD.HTTP_METHOD,
            GET_RATING_PAYLOAD.URL,
            body
        )
    };

    const getQuoteEligibility = async (execute, body) => {
        return execute(
            GET_QUOTE_ELIGIBILITY.HTTP_METHOD,
            GET_QUOTE_ELIGIBILITY.URL,
            body
        )
    };

    const getQuoteSearchResults = async (execute, body) => {
        return execute(
            GET_QUOTE_SEARCH_RESULTS.HTTP_METHOD,
            GET_QUOTE_SEARCH_RESULTS.URL,
            body
        )
    };

    const createQuoteRequest = async (execute, body) => {
        return execute(
            CREATE_QUOTE_REQUEST.HTTP_METHOD,
            CREATE_QUOTE_REQUEST.URL,
            body
        )
    };

    const getAllVendorConfigs = async (execute) => {
        return execute(
            GET_ALL_VENDOR_CONFIGS.HTTP_METHOD,
            GET_ALL_VENDOR_CONFIGS.URL
        )
    };

    const getVendorConfigForAGivenUuid = async (execute, uuid) => {
        return execute(
            GET_ALL_VENDOR_CONFIGS.HTTP_METHOD,
            `${GET_ALL_VENDOR_CONFIGS.URL}?uuid=${uuid}`
        )
    };

    const getCampaignDetails = (execute, body) => {
        return execute(
            GET_CAMPAIGN_DETAILS.HTTP_METHOD,
            GET_CAMPAIGN_DETAILS.URL,
            body
        );
    };

    const getCampaignFileDetails = (execute, body) => {
        return execute(
            GET_CAMPAIGN_FILE_DETAILS.HTTP_METHOD,
            GET_CAMPAIGN_FILE_DETAILS.URL,
            body
        );
    };

    const downloadCampaignFile = async (execute, fileName) => {
        return execute(
            DOWNLOAD_CAMPAIGN_FILE.HTTP_METHOD,
            `${DOWNLOAD_CAMPAIGN_FILE.URL}?fileName=${fileName}`
        )
    };

    const downloadProcessedCampaignFile = async (execute, campaignId) => {
        return execute(
            DOWNLOAD_PROCESSED_CAMPAIGN_FILE.HTTP_METHOD,
            `${DOWNLOAD_PROCESSED_CAMPAIGN_FILE.URL}?campaignId=` + campaignId
        )
    };

    const getAllAppointmentsByDfs = async (execute, body, headers) => {
        return execute(
            GET_ALL_APPOINTMENTS_BY_DFS.HTTP_METHOD,
            GET_ALL_APPOINTMENTS_BY_DFS.URL,
            body,
            headers
        )
    }

    const getAppliedCarriers = async (execute) => {
        return execute(
            GET_APPLIED_CARRIERS.HTTP_METHOD,
            GET_APPLIED_CARRIERS.URL
        )
    };

    const updateAppointmentsByDfs = async (execute, body, headers) => {
        return execute(
            UPDATE_APPOINTMENTS_BY_DFS.HTTP_METHOD,
            UPDATE_APPOINTMENTS_BY_DFS.URL,
            body,
            headers
        )
    }

    const handleTriggerLsaBatchProcess = async (execute) => {
        return execute(
            TRIGGER_LSA_BATCH_PROCESS.HTTP_METHOD,
            TRIGGER_LSA_BATCH_PROCESS.URL
        )
    }

    const getLsaLastRanBatchTime = async (execute) => {
        return execute(
            GET_LAST_RAN_TIME_LSA_BATCH.HTTP_METHOD,
            GET_LAST_RAN_TIME_LSA_BATCH.URL)
    }
    const getCarrierRequestAndResponse = async (execute, body) => {
        return execute(
            CARRIER_REQUEST_RESPONSE.HTTP_METHOD,
            CARRIER_REQUEST_RESPONSE.URL,
            body
        )
    };
    const getCampaignSmsStatus = async (execute, body) => {
        return execute(
            GET_CAMPAIGN_SMS_STATUS.HTTP_METHOD,
            GET_CAMPAIGN_SMS_STATUS.URL,
            body
        )
    };

    const handleEligibilityOverride = async (execute, body) => {
        return execute(
            OVERRIDE_ELIGIBILITY.HTTP_METHOD,
            OVERRIDE_ELIGIBILITY.URL,
            body
        )
    };
    const fetchUserRole = async (execute) => {
        return execute(
            GET_USER_ROLE.HTTP_METHOD,
            GET_USER_ROLE.URL
        )
    };


    const getTodaysDate = () => {
        let today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
    }


    return {
        getTopFiveCounties,
        getTopFiveProducers,
        getQuoteRequest,
        getQuoteResponse,
        getRatingPayload,
        getQuoteEligibility,
        getQuoteSearchResults,
        createQuoteRequest,
        getAllVendorConfigs,
        getVendorConfigForAGivenUuid,
        getCampaignDetails,
        getCampaignFileDetails,
        downloadCampaignFile,
        downloadProcessedCampaignFile,
        getAllAppointmentsByDfs,
        getAppliedCarriers,
        updateAppointmentsByDfs,
        handleTriggerLsaBatchProcess,
        getLsaLastRanBatchTime,
        getCarrierRequestAndResponse,
        handleEligibilityOverride,
        fetchUserRole,
        getCampaignSmsStatus
    }
}

export default AppService;