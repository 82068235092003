import {styled} from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import {tableCellClasses} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import dayjs from 'dayjs';

export const filterCriteriaOperations = ['IN', 'NOT_IN', 'BETWEEN', 'GREATER_THAN', 'LESS_THAN'];
export const filterCriteriaFields = ["ROOF_AGE", "POLICY_TYPE", "YEAR_BUILT", "EFFECTIVE_DATE", "COUNTY", "IS_FLOOD_ZONE"];
export const FIELD = 'field';
export const OPERATION_TYPE = 'operationType';
export const VALUE = 'value';
export const BASIC = 'Basic';
export const OAUTH2 = 'OAuth2';

export const TOP_FIVE_COUNTRIES = {
    HTTP_METHOD: 'GET',
    URL: '/api/admin/policy-info-report/county/'
};
export const TOP_FIVE_PRODUCERS = {
    HTTP_METHOD: 'GET',
    URL: '/api/admin/policy-info-report/producer/'
};
export const GET_QUOTE_REQUEST = {
    HTTP_METHOD: 'POST',
    URL: '/api/get-quote-request'
};
export const GET_QUOTE_RESPONSE = {
    HTTP_METHOD: 'POST',
    URL: '/api/get-quote-response'
};

export const GET_RATING_PAYLOAD = {
    HTTP_METHOD: 'POST',
    URL: '/api/get-rating-payload'
};

export const GET_QUOTE_ELIGIBILITY = {
    HTTP_METHOD: 'POST',
    URL: '/api/get-quote-eligibility'
};

export const GET_QUOTE_SEARCH_RESULTS = {
    HTTP_METHOD: 'POST',
    URL: '/api/get-search-result'
};

export const CREATE_QUOTE_REQUEST = {
    HTTP_METHOD: 'POST',
    URL: '/api/admin/create-quote'
};

export const GET_ALL_VENDOR_CONFIGS = {
    HTTP_METHOD: 'GET',
    URL: '/api/policy-renewal-config'
};

export const GET_CAMPAIGN_DETAILS = {
    HTTP_METHOD: 'POST',
    URL: '/api/campaign/getDetails'
};

export const GET_CAMPAIGN_FILE_DETAILS = {
    HTTP_METHOD: 'POST',
    URL: '/api/campaign/getMappingFiles'
};

export const DOWNLOAD_CAMPAIGN_FILE = {
    HTTP_METHOD: 'GET',
    URL: '/api/campaign/get-campaign-file'
};
export const DOWNLOAD_PROCESSED_CAMPAIGN_FILE = {
    HTTP_METHOD: 'GET',
    URL: '/api/campaign/get-campaign-processed-file'
};

export const GET_ALL_APPOINTMENTS_BY_DFS = {
    HTTP_METHOD: 'POST',
    URL: '/api/get-all-appointments-by-dfs'
}

export const GET_APPLIED_CARRIERS = {
    HTTP_METHOD: 'GET',
    URL: '/api/get-applied-carriers'
}

export const UPDATE_APPOINTMENTS_BY_DFS = {
    HTTP_METHOD: 'POST',
    URL: '/api/lsa/update-appointments-by-dfs'
}

export const TRIGGER_LSA_BATCH_PROCESS = {
    HTTP_METHOD: 'GET',
    URL: '/api/lsa/batch/start-lsa-batch'
}

export const GET_LAST_RAN_TIME_LSA_BATCH = {
    HTTP_METHOD: 'GET',
    URL: '/api/lsa/get-lsa-last-run'
}
export const CARRIER_REQUEST_RESPONSE = {
    HTTP_METHOD: 'POST',
    URL: '/api/carrier-request-response'
}
export const GET_CAMPAIGN_SMS_STATUS = {
    HTTP_METHOD: 'POST',
    URL: '/api/campaign/getCampaignSMSStatus'
}
export const OVERRIDE_ELIGIBILITY = {
    HTTP_METHOD: 'POST',
    URL: '/api/eligibility-override'
}
export const GET_USER_ROLE = {
    HTTP_METHOD: 'GET',
    URL: '/api/user/get-user-role'
};

export const layOutStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "120rem"
};

export const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#0066a1',
        color: theme.palette.common.white,
    },
    border: '1px solid rgb(0, 0, 0)',
    width: '100px',
}));

export const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    border: '1px solid rgb(0, 0, 0)',
}));

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '80vh',
    maxHeight: '80vh',
    overflowY: 'auto',
};

export const USER_ROLE = Object.freeze({
    BIZ_ANALYST: "BIZ_ANALYST",
    VIEW_ONLY: "VIEW_ONLY",
    ADMIN: "ADMIN",
    CAMPAIGN: "CAMPAIGN"
});

export const NAVIGATION_ITEMS = {
    "/quote-search": [USER_ROLE.ADMIN, USER_ROLE.BIZ_ANALYST, USER_ROLE.VIEW_ONLY],
    "/quote-submit": [USER_ROLE.ADMIN, USER_ROLE.BIZ_ANALYST],
    "/dashboard": [USER_ROLE.ADMIN, USER_ROLE.BIZ_ANALYST, USER_ROLE.VIEW_ONLY],
    "/carrier-form": [USER_ROLE.ADMIN, USER_ROLE.BIZ_ANALYST, USER_ROLE.VIEW_ONLY],
    "/vendor-renewal-view": [USER_ROLE.ADMIN, USER_ROLE.BIZ_ANALYST, USER_ROLE.VIEW_ONLY],
    "/vendor-renewal-config": [USER_ROLE.ADMIN, USER_ROLE.BIZ_ANALYST, USER_ROLE.VIEW_ONLY],
    "/campaign": [USER_ROLE.ADMIN, USER_ROLE.CAMPAIGN],
    "/qualityCheck": [USER_ROLE.ADMIN, USER_ROLE.CAMPAIGN],
    "/appointments-override-view": [USER_ROLE.ADMIN, USER_ROLE.BIZ_ANALYST, USER_ROLE.VIEW_ONLY]
};

export const groupedByRole = Object.entries(NAVIGATION_ITEMS).reduce((acc, [path, roles]) => {
    roles.forEach(role => {
        if (!acc[role]) {
            acc[role] = [];
        }
        acc[role].push(path);
    });
    return acc;
}, {});

export const formatDate = (epochSeconds) => {
    return dayjs(epochSeconds).format('YYYY-MM-DD HH:mm:ss');
};

export const centerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
};