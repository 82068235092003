import React, {useEffect, useState} from "react";
import MaterialReactTable from "material-react-table";
import {Box, Button, Grid, IconButton, Tooltip,} from "@mui/material";
import Dialog from "./Dialog";
import {Edit, FileDownload, PlayCircle, UploadFile} from "@mui/icons-material";
import {ExportToCsv} from "export-to-csv";
import {useNavigate} from "react-router-dom";
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import {loginRequest} from "../../config/authentication/authConfig";

export const CampaignMaterialTable = ({
                                          headers,
                                          data,
                                          handleChangePage,
                                          totalRows,
                                          pageNumberToUse,
                                          enableFilter,
                                          enableSort,
                                          enableRowActions,
                                          manualPagination,
                                          enableEditing,
                                          handleExecuteCampaign,
                                          handleCampaignEdit,
                                          uploadAction,
                                          displayAllRows,
                                          showAllRows
                                      }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [jsonData, setJsonData] = useState({});
  const {execute, isLoading} = useFetchWithMsal(loginRequest);
  const [pagination, setPagination] = useState({
    pageIndex: pageNumberToUse,
    pageSize: 1000,
  });
  const navigate = useNavigate();

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: headers.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  useEffect(() => {
    if (pageNumberToUse !== pagination.pageIndex) {
      handleChangePage(pagination.pageIndex);
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleViewClick = (rowData) => {
    console.log("Row Data", rowData);
    localStorage.setItem(
        "campaignDetails",
        JSON.stringify({
          campaignId: rowData?.original?.campaignId,
          campaignName: rowData?.original?.campaignName,
        })
    );
    navigate("/qualityCheck");
  };

  return (
      <div style={{width: "100%"}}>
        <Dialog
            openState={openDialog}
            viewData={selectedRowData}
            handleCloseDialog={handleClose}
            jsonToUse={jsonData}
        />
        <MaterialReactTable
            columns={headers}
            data={data}
            enableRowActions={enableRowActions}
            enableStickyHeader
            isMultiSortEvent={() => false}
            enableSorting={enableSort}
            enableFilters={enableFilter}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "Actions", //change header text
              },
            }}
            positionActionsColumn="last"
            rowCount={totalRows}
            renderRowActions={({row, table}) => (
                <Box
                    sx={{
                      display: "flex",
                      flexWrap: "nowrap",
                      gap: "2px",
                      width: "5rem",
                    }}
                >
                    {enableEditing && (<Tooltip arrow placement="left" title="Edit">
                        <IconButton
                            color="primary"
                            onClick={() => handleCampaignEdit(row.original)}
                            disabled={(row?.original?.campaignProcessed)}
                        >
                            <Edit/>
                        </IconButton>
                    </Tooltip>)}
                  {uploadAction && (
                      <Tooltip arrow placement="left" title="UploadFile">
                        <IconButton
                            color="primary"
                            onClick={() => handleViewClick(row)}
                            disabled={(row?.original?.fileUploaded)}
                        >
                          <UploadFile/>
                        </IconButton>
                      </Tooltip>
                  )}
                    {handleExecuteCampaign && (
                        <Tooltip arrow placement="left" title="Execute Campaign">
                            <IconButton
                                color="primary"
                                onClick={() => handleExecuteCampaign(row.original?.campaignId)}
                                disabled={(row?.original?.campaignProcessed)}
                            >
                                <PlayCircle/>
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            )}
            enableColumnResizing
            enableDensityToggle={false}
            enableRowVirtualization
            muiTableContainerProps={{sx: {maxHeight: "500px"}}}
            enableColumnOrdering
            enablePinning
            manualPagination={manualPagination}
            onPaginationChange={setPagination}
            state={{pagination}}
            muiTablePaginationProps={{
              rowsPerPageOptions: [25],
              showFirstButton: false,
              showLastButton: false,
            }}
            enableEditing={enableEditing}
        />
      </div>
  );
};

export default CampaignMaterialTable;
